import { withSuperJSONProps as _withSuperJSONProps, withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import React, { useContext, useEffect } from "react";
import Head from 'next/head';
import UserContext from "../components/UserContext";
import Home_1 from "./home/home_1";
import prisma from '../prisma/prisma';
import EntityMappingService from '../services/entity-mapping-service';
export const getServerSideProps = _withSuperJSONProps(async function getServerSideProps() {
  const recentRacesResult = await prisma.raceEvent.findMany({
    where: {
      isDone: true
    },
    orderBy: {
      date: 'desc'
    },
    include: {
      RacePromoter: true
    },
    take: 4
  });
  const recentRaces = EntityMappingService.mapRaceResults(recentRacesResult);
  const upcomingRaceResults = await prisma.raceCalendarItem.findMany({
    where: {
      startDate: {
        gte: new Date()
      }
    },
    orderBy: {
      startDate: 'asc'
    },
    take: 10
  });
  const upcomingRaces = EntityMappingService.mapRaceCalendarItems(upcomingRaceResults);
  const featuredFacilitiesResult = await prisma.trainingFacility.findMany({
    where: {
      isFeatured: true
    }
  });
  const buySpot = {
    id: 0,
    profilePicUrl: '/images/trainers/no-pic.jpeg',
    title: 'Your name here..',
    description: 'Want to stand out from the rest? Want to help keep AmamteurMoto.com running? By featuring your training facility, you can do both. Click here for more details.',
    url: '/apps/training-facilities/become-featured',
    city: 'USA',
    state: ''
  };
  const featuredFacilities = EntityMappingService.mapTrainingFacilities(featuredFacilitiesResult);
  (featuredFacilities ?? []).splice(1, 0, buySpot);
  return {
    props: {
      recentRaces,
      upcomingRaces,
      featuredFacilities
    }
  };
}, []);
function Home({
  recentRaces,
  upcomingRaces,
  featuredFacilities
}) {
  const {
    scrollRef
  } = useContext(UserContext);
  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  }, []);
  return <>
      <Head>
        <link rel="canonical" href="https://www.amateurmoto.com" key="canonical" />
      </Head>
      <Home_1 recentRaces={recentRaces} upcomingRaces={upcomingRaces} featuredFacilities={featuredFacilities} />
    </>;
}
export default _withSuperJSONPage(Home);